import { makeStyles } from "@mui/styles";
import hiring from "../images/hiring.jpeg";
import hiringbg from "../images/hiringbg.jpg";

const useStyles = makeStyles({
  containerfluid: {
    width: "100%",
    backgroundColor: "#0F4371",
    backgroundImage: `url(${hiringbg})`,
  },
  container: {
    margin: "2rem auto 0",
    borderRadius: "10px",
    width: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    "& div:nth-child(2)": {
      height: "auto",
      padding: "50px",
      // fontFamily: "cursive",
      "& h1": {
        fontSize: "xxx-large",
      },
    },
    "& div:nth-child(3)": {
      paddingButtom: "10px",
    },
    "@media screen and (max-width:1065px)": {
      flexDirection: "column",
    },
  },
  img_1: {
    width: "350px",
    // height: "370px",
    // borderRadius: "20px",
    "@media screen and (max-width:1065px)": {
      display: "none",
    },
  },
  button: {
    backgroundColor: "#ed8c17",
    height: "40px",
    borderRadius: "5px",
    color: "white",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "0px 10px 0px 35px",
    width: "170px",
    border: "1px white",
  },
});

function Jobs() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.containerfluid}>
        <div className={classes.container}>
          <div>
            <img
              src={hiring}
              className={classes.img_1}
              alt="Man working on computer"
            />
          </div>
          <div>
            <h1>We Are Hiring top talents</h1>
            <p>
              Unlock Your Full Potential with Us: We’re Seeking Top Talent to
              Drive Innovation, Create Impactful Solutions, and Lead the Future
              of Technology in a Dynamic, Growth-Oriented Environment. Join Our
              Elite Team and Make a Difference!
            </p>
          </div>
          <div>
            <button className={classes.button}>UPLOAD CV</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
