import React from "react";
import { Container, Grid, Typography, Paper, Box } from "@mui/material";
import PayrollOutsourcing from "../images/PayrollOutsourcing.jpg";
import TalentAcquisition from "../images/TalentAcquisition.jpg";
import TrainingPlacement from "../images/Training&Placement.jpg";
import ResumeBuilding from "../images/ResumeBuilding.jpg";
import CareerCounselling from "../images/CareerCounselling.jpg";
import VettingProcess from "../images/VettingProcess.jpg";

const customStyles = {
  container: {
    padding: "32px",
  },
  paper: {
    padding: "32px",
    textAlign: "left",
    color: "#333",
    margin: "16px 0",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  imagecont: {
    width: "100%",
    height: "280px",
  },
  section: {
    marginBottom: "32px",
  },
};

const services = [
  {
    title: "Talent Acquisition",
    description:
      "Good recruiters adapt, communicate well, and focus on predicting future performance. A structured recruitment process involves selecting the right methods and elements to hire the best talent effectively.",
    image: TalentAcquisition, // Replace with actual image URL
  },
  {
    title: "Training & Placement",
    description:
      "Our training provides practical, versatile modules with certifications, focusing on core responsibilities. These courses prepare you to handle any role under core management, ensuring you are ready for real-world tasks.",
    image: TrainingPlacement, // Replace with actual image URL
  },
  {
    title: "Payroll Outsourcing",
    description:
      "Managing employee salaries is complex for HR. Payroll outsourcing saves time, money, and effort by handling payroll tasks, allowing companies to focus on more important, resource-generating activities.",
    image: PayrollOutsourcing, // Replace with actual image URL
  },
  {
    title: "Career Counselling",
    description:
      "Career counselling offers guidance and support to resolve career issues. It relies on a trusting relationship between counsellor and counselee, helping you find solutions and navigate career challenges effectively.",
    image: CareerCounselling, // Replace with actual image URL
  },
  {
    title: "Resume Building",
    description:
      "A strong resume and cover letter double your job chances. Recruiters spend 6 seconds reviewing resumes to assess qualifications and potential, making a good resume crucial for creating a favorable impression.",
    image: ResumeBuilding, // Replace with actual image URL
  },
  {
    title: "Vetting Process",
    description:
      "Background checks require thorough planning, not just yes/no answers. Conduct them after initial interviews to avoid wasted resources, ensuring the candidates background aligns before proceeding to final hiring decisions.",
    image: VettingProcess, // Replace with actual image URL
  },
];

const Services = () => {
  return (
    <Container style={customStyles.container}>
      <Typography variant="h3" gutterBottom style={{ marginBottom: "32px" }}>
        Our Services
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper style={customStyles.paper}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <div
                style={customStyles.imagecont}
                >
                  <img
                    src={service.image}
                    alt={service.title}
                    style={customStyles.image}
                  />
                </div>{" "}
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ marginTop: "16px" }}
                >
                  {service.title}
                </Typography>
                <Typography variant="body1" align="center">
                  {service.description}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
