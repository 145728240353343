import React from "react";
import Jobs from "./js files/jobs";
import Why from "./js files/Why";
import Blog from "./js files/Blog";
import Get from "./js files/Get";
import Featured from "./js files/Featured";
import Hiring from "./js files/Hiring";
import SubHeader from "./js files/SubHeader";
import Banner from "./js files/Banner";
import BannerFooter from "./js files/BannerFooter";

function Main() {
  return (
    <>
      {/* <Header /> */}
      <SubHeader />
      <Banner />
      <BannerFooter />
      <Get />
      <Featured />
      <Why />
      <Hiring />
      {/* <Jobs /> */}
      {/* <Blog /> */}
      {/* <Footer /> */}
    </>
  );
}
export default Main;
